import React from 'react';
import Header from "../components/header";
import Footer from "../components/footer";

import IconQuality from "../assets/icon_service_quality.svg";
import IconTechnology from "../assets/icon_service_technology.svg";
import IconCommitment from "../assets/icon_service_commitment.svg";
import ServiceBanner from "../assets/banner_services.svg";

import ServiceScanning from "../assets/service_scanning.webp";
import ServiceWalkthrough from "../assets/service_walkthrough.webp";
import ServiceTour from "../assets/service_360.webp";

class Services extends React.PureComponent {
  render() {
    return (
      <>
        <Header />
        <div className="page-banner grey-bg">
          <div className="width-limit">
            <h1>Services</h1>
            <p>
              We offer development services for common XR use cases at a competitive price.
            </p>
            <img src={ServiceBanner} alt="" className="banner-image" />
          </div>
        </div>
        <div className="section">
          <h2>Why Us?</h2>
          <div className="flex width-limit" id="service-us">
            <div className="service-us-item">
              <img src={IconQuality} alt="" className="service-us-icon" />
              <strong>Quality</strong><br />
              With a significant part of our clientele based in Japan, we strive to adhere to the highest standards of quality of work expected from us.
            </div>
            <div className="service-us-item">
              <img src={IconTechnology} alt="" className="service-us-icon" />
              <strong>Technology</strong><br />
              Our team consists of industry experts with extensive experience in the latest XR technologies.
            </div>
            <div className="service-us-item">
              <img src={IconCommitment} alt="" className="service-us-icon" />
              <strong>Commitment</strong><br />
              We’re obsessed with customer satisfaction. As a young startup, we understand that it's crucial to our company’s success.
            </div>
          </div>
        </div>
        <div className="section grey-bg">
          <h2>XR services we provide</h2>
          <div className="width-limit">
            <div className="service-page-item">
              <div className="service-page-item-content">
                <h3>3D scanning</h3>
                <p>
                  Scan your handcrafted products with our photogrammetric scanning solution to create high-quality virtual assets. We built our in-house custom turntable rig, software stack, and development pipeline to automate the scanning, and postprocessing of the generated assets. This lets us capture the objects in all their details in minimal time.<br />
                  <b>&bull;</b> Accurate 3D representation<br />
                  <b>&bull;</b> Web optimized and cross-platform<br />
                  <b>&bull;</b> AR ready<br />
                  <b>&bull;</b> Wide variety of materials.</p>
              </div>
              <img className="service-page-item-img" src={ServiceScanning} alt="" />
            </div>
            <div className="service-page-item">
              <div className="service-page-item-content">
                <h3>VR Walkthrough</h3>
                <p>Create highly immersive virtual worlds that can be accessed with just a URL right from the browser. Host virtual events or meetings and create the ultimate remote collaboration experience.<br />Want to experience how a renovation looks before committing to it? We can easily create virtual experiences of spaces that do not exist, thereby reducing costly rework and errors.</p>
              </div>
              <img className="service-page-item-img" src={ServiceWalkthrough} alt="" />
            </div>
            <div className="service-page-item">
              <div className="service-page-item-content">
                <h3>360 view VR tours</h3>
                <p>Quickly generate photorealistic digital twins of your existing space. With the help of off-the-shelf 360 cameras, our web platform simplifies the creation of floorplans, markers, and annotations in just minutes. Our platform also supports analytics and heatmaps to quantify user behavior and impressions.</p>
              </div>
              <img className="service-page-item-img" src={ServiceTour} alt="" />
            </div>
          </div>
        </div>
        <Footer />
      </>
    )
  }
}

export default Services;
